$container-size: 768px;

@mixin create-container(
  $size: $container-size,
  $padding-s: space("s"),
  $padding-m: space("xxl")
) {
  $space: $padding-s;
  max-width: calc(#{$size} + #{$space * 2});
  height: 100%;

  padding-left: #{$space};
  padding-right: #{$space};

  margin-left: auto;
  margin-right: auto;

  @include breakpoint("m") {
    $space: $padding-m;
    max-width: calc(#{$size} + #{$space * 2});

    padding-left: #{$space};
    padding-right: #{$space};
  }
}

.result {
  @include create-container();

  &__gif {
    width: 100%;

    pointer-events: none;
  }

  &__subtitle {
    margin-bottom: space("m");

    @include breakpoint("m") {
      margin-bottom: space("l");
    }

    &:not(:first-child) {
      margin-top: space("m");

      @include breakpoint("m") {
        margin-top: space("l");
      }
    }
  }

  &__blocks {
    width: 100%;

    @include breakpoint("m") {
      min-width: 50rem;
    }
  }

  &__block {
    display: flex;
    align-items: center;

    max-width: 500px;
    min-height: 35px;

    padding: space("m");

    background-color: $c-white;
    box-shadow: $box-shadow;
    border-radius: $border-radius--large;

    color: $c-blue;
    text-decoration: none;

    @include transition(transform, box-shadow);

    @include breakpoint("m") {
      min-height: 40px;
    }

    &:not(:last-child) {
      margin-bottom: space("s");
    }

    &:hover,
    &:focus {
      box-shadow: $box-shadow--hover;

      transform: scale(1.02, 1.02);
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__education {
    font-size: 20px;
  }

  &__arrow {
    flex-shrink: 0;
    flex-grow: 0;

    width: 24px;
    height: 24px;

    margin-left: space("m");

    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.3 5.3a1 1 0 0 1 1.4 0l6 6c.4.4.4 1 0 1.4l-6 6a1 1 0 0 1-1.4-1.4l5.3-5.3-5.3-5.3a1 1 0 0 1 0-1.4Z' fill='%2309639B' stroke='%2309639B' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }

  &__again {
    margin-top: space("l");
    cursor: pointer;
  }

  &__website {
    margin-top: space("s");
    cursor: pointer;
  }
}

.visit-us {
  margin-top: space("xxl");
  max-width: 500px;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400&display=swap");

$font-size--xxs: clamp(14px, 2vw, 15px);
$font-size--xs: clamp(15px, 2.5vw, 18px);
$font-size--s: clamp(20px, 2.5vw, 22px);
$font-size--m: clamp(20px, 3vw, 24px);
$font-size--l: clamp(24px, 3vw, 28px);
$font-size--xl: clamp(28px, 3.5vw, 36px);
$font-size--xxl: clamp(32px, 3.5vw, 40px);

$font-size--form: clamp(16px, 2vw, 17px);

%font-base {
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: inherit;
  font-style: normal;
}

%font-base--italic {
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: inherit;
  font-style: italic;
}

%font-base--bold {
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
}

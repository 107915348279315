html {
  font-size: 62.5%; /* Now 10px = 1rem! */
}

body {
  @extend %font-base;
  font-size: $font-size--m;
  line-height: 140%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $c-black;
}

strong {
  @extend %font-base--bold;
  font-weight: inherit; // safari fix
}

h1,
.heading-1 {
  @extend %font-base--bold;
  font-size: $font-size--xxl;
  line-height: 120%;
}

h2,
.heading-2 {
  @extend %font-base--bold;
  font-size: $font-size--xl;
  line-height: 120%;
}

h3,
.heading-3 {
  @extend %font-base--bold;
  font-size: $font-size--l;
  line-height: 120%;
}

p > strong {
  @extend %font-base--bold;
}

$animation-speed--fast: 150ms;
$animation-speed--default: 300ms;
$animation-speed--slow: 500ms;

$animation-transition--strong-in: cubic-bezier(0.15, 0, 0, 1);
$animation-transition--strong-in-out: cubic-bezier(0.5, 0, 0.5, 1);

/*
* How to use this mixin:
*
* @include transition(transform);
* @include transition(background-color);
*/

@mixin transition($x...) {
  $result: ();
  @for $i from 1 through length($x) {
    $prop: #{nth($x, $i)};
    $result: append(
      $result,
      (#{$prop} $animation-speed--default $animation-transition--strong-in)
    );
    @if $i != length($x) {
      $result: append(
        $result,
        unquote(
          $string: ",",
        )
      );
    }
  }
  transition: $result;
}

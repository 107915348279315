.form {
  @include create-container(650px);

  &__text {
    margin-top: space("m");
  }

  &__form {
    margin-top: space("m");
  }

  &__errors {
    margin-left: space("m");
    font-size: $font-size--xs;
    color: $c-red;
  }

  &__submit {
    margin-top: space("l");

    cursor: pointer;

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}

fieldset {
  width: 100%;

  > * + * {
    margin-top: space("m");
  }
}

label {
  display: block;

  width: 100%;

  @extend %font-base;
  font-size: $font-size--xs;
  line-height: 130%;
}

input,
option {
  display: block;

  width: 100%;

  margin-top: space("xs");
  padding: space("s");

  border: 1px solid $c-grey;

  @extend %font-base;
  font-size: $font-size--form;
}

select {
  max-width: 38rem;

  margin-left: space("xs");
  padding: space("xs");

  border: 1px solid $c-grey;

  @extend %font-base;
  font-size: $font-size--form;
  text-overflow: ellipsis;

  @include breakpoint("m", "max-width") {
    max-width: 30rem;

    margin-top: space("xs");
    margin-left: 0;
  }
}

option {
  padding: space("xs");
}

.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  input[type="checkbox"] {
    flex: space("m") 0 0;

    margin-top: space("xxs");
    margin-right: space("xs");
  }
}

@keyframes questionIn {
  from {
    opacity: 0;

    transform: scale(0.9);
  }

  to {
    opacity: 1;

    transform: scale(1);
  }
}

.question {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  @include create-container();

  text-align: center;

  opacity: 0;
  animation: questionIn 750ms $animation-transition--strong-in forwards;

  &__title {
    margin-top: space("m");

    font-weight: 400 !important;

    @include breakpoint("m") {
      margin-tip: space("xl");
    }
  }

  &__subtitle {
    margin-top: space("xs");
  }

  &__steps {
    letter-spacing: 0.1em;

    font-size: $font-size--s;
  }
}

@import "options";

.share-links {
  margin-top: space("l");

  color: rgba($c-black, 0.5);
  font-size: $font-size--xs;

  &__list {
    list-style-type: none;

    display: flex;

    > * + * {
      margin-left: space("s");
    }
  }
}

.share-link {
  $root: &;

  display: flex;
  align-items: center;

  &__label {
    padding-left: space("xxs");

    color: $c-blue;
    font-size: $font-size--xs;
    font-weight: 700;
  }

  &:hover,
  &:focus {
    #{$root}__label {
      color: $c-green;
      text-decoration: underline;

      @include transition(color);
    }
  }
}

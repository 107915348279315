/* Primary colors */
$c-green: #41a482;
$c-red: #de5146;
$c-blue: #09639b;

/* Greys */
$c-white: #ffffff;
$c-grey: #d2ddf1;
$c-black: #2c2a50;

/* Funcional colors */
$c-success: $c-green;
$c-error: $c-red;

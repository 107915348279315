.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: calc(#{space("xs")} - 2px) space("l") calc(#{space("xs")} + 2px);

  background-color: $c-green;
  border-radius: $border-radius;

  @extend %font-base--bold;
  font-size: $font-size--s;
  text-decoration: none;
  color: $c-white;

  @include transition(transform);

  &:not(:disabled) {
    &:hover,
    &:focus {
      transform: scale(0.95);
    }
  }
}

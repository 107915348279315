html,
body {
  height: 100%;
}

body {
  position: relative;

  max-width: -moz-available;
  max-width: -webkit-fill-available;
}

.app {
  position: relative;

  height: 100%;
}

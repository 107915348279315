@keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(0, -200px, 0) scaleY(1.1);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, 5px, 0) scaleY(0.9);
    }

    75% {
        transform: translate3d(0, -3px, 0) scaleY(0.95);
    }

    90% {
        transform: translate3d(0, 1px, 0) scaleY(0.985);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.background {
    $root: &;

    position: relative;

    display: flex;
    flex-direction: column;

    min-height: 100%;
    
    &__content {
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;

        margin-bottom: space("xxxl");
        padding-top: space("xxxxl");

        @include breakpoint("m") {
            padding-top: space("xxl");
        }
    }

    &__visuals {    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        height: 120px;
    
        margin-top: auto;
        padding: 0 space("l");

        @include breakpoint("m") {
            height: 220px;
        }

        > svg {
            height: 150px;

            @include breakpoint("m") {
                height: 225px;
            }

            &.is-smaller {
                height: 100px;
    
                @include breakpoint("m") {
                    height: 170px;
                }
            }
        }
    }

    &__visual {
        overflow: visible;
    }

    &__logo {
        position: absolute;
        top: space("s");
        left: space("s");

        width: 100px;
        height: 33px;

        @include breakpoint("m") {
            top: 2.5rem;
            left: 2.5rem;

            width: 135px;
            height: 45px;
        }
    }

    &__corner {
        position: fixed;
        top: 0;
        right: 0;
        
        width: 15vw;
        height: 15vw;
        max-width: 180px;
        max-height: 180px;

        background: url("../../assets/visuals/corner.svg") no-repeat;
        background-size: contain
    }

    &.is-animated {
        #{$root}__part {
            opacity: 0;
            transform: translate3d(0, -500px, 0) scaleY(3);
            animation: bounceInDown 350ms forwards;
        }
    }
}

@for $i from 1 through 10 {
    .background.is-animated .background__part:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.1}s;
    }
}
.splash {
  @include create-container(940px);

  text-align: center;

  &__text {
    margin-top: calc(#{space("l")} - 3px);

    @include breakpoint("m") {
      margin-top: calc(#{space("xl")} - 3px);
    }
  }

  &__button {
    margin-top: space("l");

    @include breakpoint("m") {
      margin-top: space("xl");
    }
  }
}

html,
body,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
p,
figure,
button,
form,
fieldset,
select,
option,
input,
label,
.app {
  margin: 0;
  padding: 0;

  border: 0;
}

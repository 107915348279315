.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: calc(#{space("s")} - 1px) space("s") calc(#{space("s")} + 2px);

  background-color: $c-grey;

  @include breakpoint("m") {
    flex-direction: row;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;

    list-style: none;
    text-align: center;

    line-height: 110%;
  }

  &__link {
    margin: 0 space("xxs");
    padding: space("xxs");

    font-size: $font-size--xs;
    color: $c-black;
    text-decoration: none;

    @include breakpoint("m") {
      padding: space("xs");
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__socials {
    display: flex;
    align-items: center;

    margin-top: space("xs");

    font-size: $font-size--xxs;

    @include breakpoint("m") {
      margin-top: 0;
      margin-left: space("s");
    }

    > ul {
      display: flex;
      align-items: center;

      margin-left: space("xs");

      list-style: none;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2rem;
    height: 2rem;

    padding: space("xxs");

    &-icon {
      fill: $c-black;

      &.is-instagram,
      &.is-youtube {
        margin-top: 1px;
      }

      &.is-youtube {
        margin-left: 1px;
      }

      &.is-tiktok {
        width: 1.3rem;
      }
    }
  }
}

.options {
  display: flex;
  align-content: center;
  justify-content: center;

  margin-top: space("xxl");
}

.option {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 75px;
  height: 75px;

  margin: 0 space("xxs");

  background: $c-white;
  box-shadow: $box-shadow;
  border-radius: $border-radius--large;
  cursor: pointer;
  vertical-align: middle;
  overflow: hidden;
  opacity: 0.8;

  font-size: 32px;
  line-height: 100%;

  will-change: transform;
  transform-origin: center center;
  @include transition(box-shadow, transform, opacity);

  @include breakpoint("m") {
    width: 75px;
    height: 75px;

    margin: 0 space("m");

    font-size: 35px;
  }

  &:hover,
  &:focus {
    opacity: 1;
    box-shadow: $box-shadow--hover;

    transform: scale(1.1, 1.1);
  }

  &:focus,
  &.is-active {
    opacity: 1;
    background: $c-green;
  }
}
